body {
  overflow: auto;
  background-color: #f5f5f5 !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background-color: #b8b8b8;
  border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #b9b9b9;
}

#zmmtg-root {
  display: none;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");
